
import { defineComponent, onMounted, ref } from "vue";
import { getOrganization } from "@/core/services/JwtService";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";

export default defineComponent({
  name: "Settings Page",
  setup() {
    let organizationId = ref<string | null>("");

    onMounted(async () => {
      organizationId.value = getOrganization();
      setCurrentPageTitle("Settings");
    });

    return {
      organizationId,
    };
  },
});
